import * as React from "react"
import Home from '../pages/Home'
import "nprogress/nprogress.css";
// markup
const IndexPage = ({ path, location }) => {
  const myLocation = location.pathname
  return (
    <Home path={path} myLocation={myLocation} />
  )
}

export default IndexPage
